@import '../../base/base';
.widget-content-area {
  border: 1px solid #e0e6ed;

  h1, h2, h3, h4, h5, h6 {
    color: #3b3f5c;
  }
}

.swiper-container .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper-pagination {
  bottom: -37px;
  left: 0;
  right: 0;

  .swiper-pagination-bullet {
    margin-right: 5px;

    &.swiper-pagination-bullet-active {
      background-color: #6c4dff;
    }
  }
}

/*
    ===============================
        Product Details Content
    ===============================
*/

#main-slider .splide__track {
  border-radius: 12px;
}

.splide--nav > {
  .splide__slider > .splide__track > .splide__list > .splide__slide.is-active, .splide__track > .splide__list > .splide__slide.is-active {
    border: none;
  }
}

#main-slider .splide__list .glightbox {
  display: block;
  height: 100%;

  img {
    display: block;
    width: 100%;
    border-radius: 12px;
    height: 100%;
  }
}

#thumbnail-slider {
  margin-top: 30px;
  max-width: 500px;
  margin-right: auto;
  margin-left: auto;

  .splide__track {
    border-radius: 8px;
  }

  .splide__slide {
    border-radius: 8px;
    filter: blur(1px);
    transition: filter .5s;

    &.is-active {
      filter: blur(0);
    }
  }

  .splide__arrow--prev {
    left: -13px;
  }

  .splide__arrow--next {
    right: -13px;
  }

  .splide__arrow {
    opacity: 1;
    background: #e0e6ed;
  }
}

/*
    ===============================
        Product Details Content
    ===============================
*/

.product-details-content {

  hr {
    border-top: 1px solid #bfc9d4;
  }

  .bootstrap-touchspin-injected {

    input {
      border: 1px solid #e0e6ed;
    }
    
    .input-group-prepend {
      button {
        background-color: #eaeaec;
        border-color: #e0e6ed;
        box-shadow: none;
        color: #000!important;
        
        &:hover {
          background-color: #d3d3d3;
          border-color: #d3d3d3;
          color: #000!important;
        }
      }
    }
    
    .input-group-append {
      button {
        background-color: #eaeaec;
        border-color: #e0e6ed;
        box-shadow: none;
        color: #000!important;

        &:hover {
          background-color: #d3d3d3;
          border-color: #d3d3d3;
          color: #000!important;
        }
      }
    }

  }
  

  .product-helpers {
    font-size: 13px;
    font-weight: 600;
    color: #3b3f5c;

    &:hover {
      text-decoration: underline;
    }
    
  }

  .product-title {
    font-weight: 700;
  }
  
  .review {

    display: inline-block;
    cursor: pointer;
    
    svg {
      fill: $warning;
      color: $warning;
      stroke-width: 0;
      width: 20px;
      height: 20px;
      vertical-align: sub;
    }

    .rating-score {
      font-weight: 700;
      color: #000;
    }

    .rating-count {
      color: #888ea8;
      font-weight: 600;
    }

  }
  

  .pricing {
    font-size: 25px;
    font-weight: 700;
    color: #3b3f5c;

    .regular-price {
      margin-right: 5px;
      color: #888ea8;
      font-size: 16px;
      text-decoration: line-through;
      vertical-align: middle;
      display: inline-block;
    }
    .discounted-price {
      vertical-align: middle;
      display: inline-block;
    }
  }

  .color-swatch {
    font-size: 16px;
    font-weight: 600;
    color: #000;

    .form-check {
      margin-right: 0;
      margin-bottom: 0;

      .form-check-input {
        border: none;
      }
    }

    .form-check-input {
      &:checked {
        &[type=radio] {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
        }

        border: none;
      }

      width: 26px;
      height: 26px;
      cursor: pointer;
      border-radius: 8px;
    }
  }

  .secure-info {
    padding: 12px 12px;
    background: #f1f2f3;
    border-radius: 14px;
    display: flex;
    
    svg {
      margin-right: 10px;
      color: #e2a03f;
      fill: #e2a03f5e;
    }

    p {
      margin-bottom: 0;
      font-size: 12px;
      font-weight: 600;
      color: #000;
      letter-spacing: 1px;
      align-self: center;
    }
  }
  
  .size-selector, .quantity-selector {
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  .product-description {
    font-size: 15px;
    font-weight: 200;
    color: #bfc9d4;
  }
}

/*
    =================================
        Production Descriptions
    =================================
*/

.production-descriptions {
  padding: 20px;

  .pro-des-content {
    max-width: 1040px;
    margin: 0 auto;

    .accordion {

      hr {
        border-top: 1px solid #bfc9d4;
      }

      .card {
        border: none;
        border-bottom: 1px solid #e0e6ed;
        box-shadow: none;
        border-radius: 0;
        margin-bottom: 0;
        
        &:first-child {
          border-top: 1px solid #e0e6ed;
        }

        .card-header {
          section {
            > div {
              padding: 13px 0;
              color: #3b3f5c;
              font-size: 15px;
              font-weight: 600;

              .accordion-icon {
                svg {
                  width: 26px;
                  height: 26px;
                  color: #4361ee;
                  fill: none;
                  stroke-width: 1.5;
                  margin-right: 0;
                }
              }
              
              &:not(.collapsed) {
                border-bottom: 1px solid #e0e6ed;
                color: #4361ee;
              }

              &.collapsed {
                .accordion-icon {
                  svg {
                    color: #3b3f5c;
                  }
                }
              }
              
            }
          }
        }

        .card-body {
          padding: 24px 0;

          p {
            font-size: 14px;
            color: #3b3f5c;
          }

          .media {
            img {
              border: none;
              width: 48px;
              height: 48px;
              border-radius: 8px;
            }

            .media-body {

              position: relative;
              
              h4 {
                font-size: 16px;
                font-weight: 600;
              }

              .stars svg {
                fill: $warning;
                color: $warning;
                stroke-width: 0;
                width: 17px;
                height: 17px;
        
                &.empty-star {
                  stroke-width: 1px;
                  fill: #fcf5e9;
                  opacity: .5;
                }
                
              }

              .meta-tags {
                position: absolute;
                top: 0;
                right: 0;
                color: #506690;
              }
              
            }
          }
          
        }
        
      }
      
    }
    
  }

  .nav-link {
    font-size: 15px;
    letter-spacing: 2px;
    font-weight: 700;

    &.active {
      border-radius: 8px;
    }
  }

  .tab-content p {
    color: $dark;
  }

  .product-reviews {
    background: #fafafa;
    padding: 32px 50px;
    border-radius: 26px;
    border: 1px solid #e0e6ed;

    .reviews {
      h1 {
        font-weight: 600;
        font-size: 40px;
      }
  
      .stars svg {
        fill: $warning;
        color: $warning;
        stroke-width: 0;

        &.empty-star {
          stroke-width: 1px;
          fill: #fcf5e9;
          opacity: .5;
        }
        
      }
  
      span {
        font-size: 15px;
        font-weight: 200;
        color: #3b3f5c;
        letter-spacing: 1px;
      }
    }
  
    .review-progress {
      p {
        margin-bottom: 2px;
        font-size: 14px;
        font-weight: 500;
      }
  
      .progress {
        height: 8px;
        border-radius: 10px;
        margin-bottom: 0;
        background: #e0e6ed;
      }
  
      .progress-bar {
        border-radius: 0;
      }
    }
  
    .media {
      img {
        border-radius: 15px;
        border: none;
      }
  
      .media-body {
  
        .media-heading {
          font-size: 18px;
          color: #000;
          font-weight: 600;
        }
        
      }
  
      .stars svg {
        fill: $warning;
        color: $warning;
        stroke-width: 0;
        width: 16px;
        height: 16px;
      }
    }
  }

}

@media (max-width: 1199px) {
  .production-descriptions {
    padding: 0;
  }
}

@media (max-width: 575px) {
  .production-descriptions {

    .product-reviews {
      padding: 32px 32px;
    }
    
    .media {
      display: block;

      img {
        margin-bottom: 15px;
      }
    }
  }

  #main-slider .splide__slide {
    width: 320px !important;
    height: 320px !important;
    margin: 0 auto;
  }
}